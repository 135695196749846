<template>
  <div class="bg-white shadow-md rounded-lg">
    <div class="p-5 cursor-pointer">
      <router-link :to="{ name: 'my-task-listings', params: { id: board.id } }">
        <div class="flex items-center">
          <div class="w-0 flex-1">
            <div class="mt-1 text-sm text-gray-900 truncate block">
              {{ board.title }}
            </div>
          </div>
        </div>
        <div class="mt-3 pr-6">
          <Chart :coords="taskCoords"/>
        </div>
      </router-link>
    </div>
    <div class="bg-gray-50 px-5 py-3 flex">
      <CardIcon/>
      <div class="text-sm w-full flex justify-between">
        <a href="#" class="text-sm font-small text-gray-500 cursor-default">
          {{ board.tasks_total}}
        </a>
        <a href="#" class="text-sm font-small text-gray-500 ml-3 cursor-default">
          {{ $t('global.cardOptions.updated') }} {{ board.updatedAt | parseBoardDate }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import CardIcon from '@/components/Icons/CardIcon';
import Chart from '@/components/Board/Chart/Chart';
import withBoardChart from '@/mixins/Chart/withBoardChart';

export default {
  name: 'Card',
  mixins: [withBoardChart],
  components: {
    Chart,
    CardIcon
  },
  props: {
    board: {
      type: Object,
      'default': Object
    },
    allDaysOfMonth: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      taskCoords: [],
      taskCount: 0
    }
  },
  mounted() {
    this.taskCount = this.board.tasks.length
    this.tasks = this.board.tasks;
    this.getTasks();
  }
}
</script>

<style scoped>

</style>
