<template>
  <div v-if="!loading">
    <DataTableSearch @handleSearchTable="handleSearchTable"/>
    <div class="h-ful border-t border-b">
      <div class="px-4 py-5 bg-white">
          <transition-group type="transition" name="flip-list"
                            class="row wrap justify-space-around mt-2 grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
            <div v-for="board in boards" :key="board.id" class="row-v">
              <Card :board="board" :allDaysOfMonth="allDaysOfMonth" />
            </div>
          </transition-group>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import DataTableSearch from '@/components/DataTableSearch/DataTableSearch';
import { getDaysArrayByMonth } from '@/helpers/getDaysArrayByMonth';
import Card from '@/components/MyTask/Card/Card';
export default {
  name: 'MyTaskBoard',
  components: {
    DataTableSearch,
    Card
  },
  data() {
    return {
      loading: true,
      data: [],
      boards: [],
      searchValue: ''
    }
  },
  watch:{
    getBoardsWithTasks(newValue) {
      this.data = newValue;
    },
    data(newValue) {
      this.boards = newValue;
      this.loading = false;
    }
  },
  methods: {
    ...mapActions({
      handleGetMyBoards: 'fetchMyBoards'
    }),
    handleSearchTable(value) {
      this.searchValue = value;
      this.boards = this.data.filter((board) => {
        if (board.title.toLowerCase().includes(value)) {
          return board;
        }
      })
    }
  },
  mounted() {
    this.handleGetMyBoards();
  },
  computed:{
    ...mapGetters([
      'getBoardsWithTasks'
    ]),
    allDaysOfMonth() {
      return getDaysArrayByMonth();
    }
  }
}
</script>

<style scoped>
.bg-white.overflow-hidden.shadow.rounded-lg.cursor-pointer {
  height: 100%;
}
</style>
